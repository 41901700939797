<template>
  <el-container>
    <el-aside width="200px">
      <el-menu
        default-active="2"
        class="el-menu-vertical-demo"
        @select="handleSelect"
        :default-openeds="openList">
        <el-submenu index="report">
          <span slot="title">{{$t('reportMain.gpsReport')}}</span>
          <el-menu-item index="/device/deviceStatistic/odometerReport">{{$t('reportMain.odometerItem')}}</el-menu-item>
          <el-menu-item index="/device/deviceStatistic/alterReport">{{$t('reportMain.alterItem')}}</el-menu-item>
          <el-menu-item index="/device/deviceStatistic/speedReport">{{$t('reportMain.overSpeedItem')}}</el-menu-item>
          <el-menu-item index="/device/deviceStatistic/bikeReport">{{$t('reportMain.bikeItem')}}</el-menu-item>
          <el-menu-item index="/device/deviceStatistic/historyReport">{{$t('reportMain.historyItem')}}</el-menu-item>
          <el-menu-item index="/device/deviceStatistic/stopReport">{{$t('reportMain.stopItem')}}</el-menu-item>
<!--          <el-menu-item index="/device/deviceStatistic/oilReport">{{$t('reportMain.oilItem')}}</el-menu-item>-->
        </el-submenu>
      </el-menu>
    </el-aside>
    <el-container>
      <el-main>
        <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import router from '@/router'

export default {
  name: 'reportMain',
  data () {
    return {
      openList: ['report', '/device/deviceStatistic/alterReport']
    }
  },
  methods: {
    handleSelect (key, keyPath) {
      router.push({ path: key.toString() })
    }
  }
}
</script>

<style scoped>

.el-container {
  top: 0;
  position: relative;
  margin: 0 0 0 0 !important;
  padding-top: 0;
}

.el-main {
  margin: 0 0 0 0 !important;
  padding-top: 0;
}

</style>
